import { register } from 'tmr/tmr/ngModuleRegistry'

// Themeing.
import './theme/theme.scss'

// Constants.
import * as apiPrefix from './constants/apiPrefix'
import * as appName from './constants/appName'
import * as defaultApiEndpoints from './constants/defaultApiEndpoints'
import * as frontendCapabilities from './constants/frontendCapabilities'

// See src/app/examples/index.js for examples

// Add everything to the module registry.
register('tmr.BRAND', apiPrefix, appName, defaultApiEndpoints, frontendCapabilities)
